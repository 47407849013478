import React from 'react'
import IrisImg from '../../../Shared/IrisImg'
import './Applications.global.scss'

const Applications = ({ images }) => (
  <div className="applications-container section">
    <div className="content-container">
      <div className="accent-border" />
      <h1>Virtual Reality Applications and Examples</h1>

      <h2>Gaming</h2>
      <p className="block">
        VR’s explosion in popularity owes much to the gaming community. For many
        enthusiasts, VR brings an immediacy to gaming experiences that
        traditional consoles can’t touch.
      </p>
      <p className="block">
        Today, VR gaming takes many forms. Gamers interested in VR versions of
        standard games can enjoy open-world RPGs like The Elder Scrolls V:
        Skyrim and Fallout 4. On the other hand, games specifically tailored to
        VR’s strengths and limitations — like the popular rhythm and movement
        game Beat Saber — have also achieved success. Even games originally
        popular on mobile platforms, like Fruit Ninja, have been ported into VR.
      </p>

      <h2>Enterprise</h2>
      <p className="block">
        Though VR’s current landscape has been partially formed by the gaming
        community, enterprise interests have also played a role. VR technology
        brings workflows into the future for professional areas like:
      </p>
      <ul className="block">
        <li>
          <p>operations and safety training</p>
        </li>
        <li>
          <p>creative production</p>
        </li>
        <li>
          <p>sales and marketing</p>
        </li>
      </ul>
      <p className="block">
        For creative professionals, virtual reality represents a whole new
        territory to explore artistically. Google’s{' '}
        <span className="bold">Tilt Brush</span> allows users to paint in three
        dimensions, while <span className="bold">Mindshow</span> gives aspiring
        directors the tools to create animated films in VR.
      </p>
      <IrisImg className="gaming image block" file={images.prospect} />
      <p className="block">
        At IrisVR, we empower the AEC (architecture, engineering, and
        construction) industry with VR. Using IrisVR Prospect, designers,
        engineers and VDC teams can view architectural models in immersive 3D
        and host meetings with up to 12 participants. Everyone can see the model
        as it is intended to be built, ultimately driving better communication
        and collaboration between designers, engineers, and clients. With IrisVR
        Scope, AEC professionals can view{' '}
        <span className="bold">360-degree panoramas</span> with a smartphone and
        a mobile headset like Google Cardboard.
      </p>
      <p className="block">
        With the release of high-grade, business-aimed headsets like Vive
        Enterprise, it’s clear that VR trendsetters are tapping into the
        potential of VR for professional usage. Only time will tell how VR
        technology will continue to transform industries.
      </p>

      <h2>Social</h2>
      <p className="block">
        When Facebook purchased Oculus VR in March 2014, founder and CEO Mark
        Zuckerberg made it clear that he believed VR would be a major player in
        the future of social media and communications. “Oculus has the chance to
        create the most social platform ever,” Zuckerberg claimed, adding that
        VR could “change the way we work, play, and communicate.” Since then, a
        number of VR social networks have emerged:
      </p>
      <ul className="block">
        <li>
          <p>
            <span className="bold">vTime</span> is a free-to-play VR app that
            allows up to 4 users at once to chat with friends and colleagues
            while being represented by a VR avatar.
          </p>
        </li>
        <li>
          <p>
            <span className="bold">Sansar</span>, a VR social network developed
            by the creators of Second Life, allows users to explore hundreds of
            virtual environments, upload their own creations into VR, and even
            partake in a VR marketplace with other users.
          </p>
        </li>
        <li>
          <p>
            Even Facebook itself has entered the VR social network industry with{' '}
            <span className="bold">Spaces</span>, an app released in 2017.
          </p>
        </li>
      </ul>
    </div>
  </div>
)

export default Applications
