import React from 'react'
import IrisImg from '../../../Shared/IrisImg'

const Vive = ({ image }) => (
  <div className="vive-container">
    <h3>HTC Vive</h3>
    <p className="block">
      Valve and HTC’s computer-powered Vive headset has become known for
      out-of-the-box <span className="bold">room-scale VR</span>. Vive
      accomplishes this with its two Lighthouse base stations, which together
      create a room-sized virtual play space.
    </p>
    <p className="block">
      Vive boasts high-resolution visuals and strong audio, making it attractive
      for VR users interested in especially immersive experiences. In addition,
      Vive’s state-of-the-art safety system keeps users from walking into
      real-world barriers by automatically displaying virtual walls or camera
      feeds when necessary.
    </p>
    <IrisImg className="vive image block" file={image} />
    <p className="block">
      HTC has announced several new headsets for 2019 - including the HTC Vive
      Pro Eye (with eye-tracking capabilities) and the HTC Vive Cosmos (a new
      PC-powered headset).
    </p>
  </div>
)

export default Vive
