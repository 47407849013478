import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from '../components/Shared'
import WhyVR, { helmetProps } from '../components/WhyVR'

export const query = graphql`
  {
    splash: file(
      name: { eq: "1_splash" }
      relativeDirectory: { eq: "why-vr" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxHeight: 625, maxWidth: 1053, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    collab: file(
      name: { eq: "3_collab" }
      relativeDirectory: { eq: "why-vr" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxHeight: 593, maxWidth: 1053, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    vive2: file(name: { eq: "3_vive" }, relativeDirectory: { eq: "why-vr" }) {
      id
      name
      childImageSharp {
        fluid(maxHeight: 790, maxWidth: 1053, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    cardboard: file(
      name: { eq: "4_cardboard" }
      relativeDirectory: { eq: "why-vr" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxHeight: 592, maxWidth: 1053, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    prospect: file(
      name: { eq: "5_prospect" }
      relativeDirectory: { eq: "why-vr" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxHeight: 592, maxWidth: 1053, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default React.memo(({ data }) => (
  <>
    <Helmet {...helmetProps} />
    <WhyVR
      images={{
        splash: data.splash,
        computers: {
          collab: data.collab,
          vive: data.vive2,
        },
        mobile: {
          cardboard: data.cardboard,
        },
        applications: {
          prospect: data.prospect,
        },
      }}
    />
  </>
))
