import React from 'react'
import './Future.global.scss'

const Future = () => (
  <div className="future-container section">
    <div className="content-container">
      <div className="accent-border" />
      <h1>The Future of Virtual Reality</h1>

      <p className="block">
        Virtual reality has developed from a science-fiction staple into a
        cultural force to be reckoned with.
      </p>
      <p className="block">
        As the field continues to expand, the level of immersion users
        experience will keep growing. Oculus is leading the way with its Half
        Dome prototype, a potential successor to the Rift which would track
        users’ eyes while giving them a massive 140 degree field of view. Leap
        Motion is also contributing to VR’s development with controller-less,
        finger-tracking technology. One day, VR may even involve mixing and
        matching experimental technologies like haptic feedback and
        omnidirectional treadmills.
      </p>
      <p className="block">
        As the technology advances, so will VR’s popularity in fields from
        gaming and social media to architecture and design. No one knows exactly
        what the future will hold. But whatever direction VR does go in, one
        thing is for sure: it’s here to stay.
      </p>
    </div>
  </div>
)

export default Future
