import React from 'react'

const Quest = () => (
  <div className="quest-container">
    <h3>Meta Quest 2 and the Future of Mobile VR</h3>
    <p className="block">
      Facebook and Oculus VR announced their newest headset, the{' '}
      <a
        href="https://blog.irisvr.com/oculus-quest-vs-rift-vs-go"
        target="_blank"
        rel="noopener noreferrer"
      >
        Meta Quest 2
      </a>
      , at October 2018’s Oculus Connect 5 event.
    </p>
    <p className="block">
      Quest will take up a new space in the growing consumer headset industry.
      It will be able to run much of the same software as Rift while not
      requiring a gaming computer. This means immersive VR will be more
      affordable than ever. In addition, users will not need to work through an
      involved setup process.
    </p>
    <p className="block">
      The possibilities of VR headsets that walk the line between mobility and
      utility are promising. At Connect 5, users played an “arena-scale” VR demo
      of popular video game Dead & Buried that took full advantage of Quest’s
      capabilities. As mobile VR platforms continue to improve, more use cases
      like this will become possible. Microsoft HoloLens, which runs Windows MR,
      is another platform (currently available in a developer package) that
      promises immersive, mobile VR.
    </p>
  </div>
)

export default Quest
