import React from 'react'

const WindowsMR = () => (
  <div className="wmr-container">
    <h3>Windows MR</h3>
    <p className="block">
      Another option for computer-powered VR is Windows MR. Headsets that run
      Windows MR — like the Samsung Odyssey and Asus Windows Mixed Reality
      Headset — feature inside-out tracking capabilities.
    </p>
  </div>
)

export default WindowsMR
