import React from 'react'
import './Hardware.global.scss'

const Hardware = ({ widthClass }) => (
  <div className={`hardware-container section ${widthClass}`}>
    <div className="content-container">
      <div className="accent-border" />
      <h1>Virtual Reality Hardware</h1>
      <p className="block">
        What does virtual reality hardware look like today? VR experiences
        usually involve a combination of visuals, audio, and motion. Different
        VR systems provide users with these elements in different ways and at
        different levels of quality. Depending on what sorts of virtual worlds
        you hope to enjoy, one or more of these systems is right for you.
      </p>
      <p className="block">
        Consumers interested in VR have many options to choose from. Powerful,
        computer-based VR experiences are now within their reach. At the same
        time, smartphone-based headsets like Google Cardboard and Samsung Gear
        VR have made VR more accessible than ever.
      </p>
      <p className="block">
        But no matter what route you go, you will generally need 3 main
        elements:
      </p>
      <div className="images-container">
        <div className="block image-text-container">
          <p>
            1) <span className="bold">PC/Console/Smartphone</span> — Today, the
            majority of VR experiences are powered by a PC, console, or
            smartphone. These systems render the content for your VR experience.
          </p>
        </div>
        <div className="block image-text-container">
          <p>
            2) <span className="bold">Headset</span> — The headset provides the
            visual and auditory elements of your VR experience. For
            smartphone-powered VR, the headset includes a slot to place the
            smartphone, which acts as the visual display. For computer-powered
            VR, the headset has wires that tether to an external computer.
          </p>
        </div>
        <div className="block image-text-container">
          <p>
            3) <span className="bold">Controllers and Sensors</span> —
            Controllers allow users to interact with the virtual environment.
            These are paired with sensors that track the user’s location and
            position. Together, controllers, sensors, and other input devices
            create a virtual world users can navigate through and play with.
          </p>
        </div>
      </div>
    </div>
  </div>
)

export default Hardware
