import React from 'react'

const History = () => (
  <div className="history-container section">
    <div className="content-container">
      <div className="accent-border" />
      <h1>History of Virtual Reality</h1>
      <p className="block">
        Virtual reality has existed in concept since the 19th century, and in
        practice since the 1960s. But in the last ten years, VR has undergone a
        revolution of mainstream availability and adoption.
      </p>
      <p className="block">
        Some experts believe the term “virtual reality” was first used by
        playwright Antonin Artaud in 1938. Around the same time, science fiction
        authors wrote stories that dealt with virtuality as a subject. Stanley
        G. Weinbaum’s 1935 story “Pygmalion’s Spectacles” is one example of
        this.
      </p>
      <p className="block">
        In the late 1960s, Ivan Sutherland built The Sword of Damocles, the
        first example of a “head-mounted display” (HMD) in the history of
        immersive technology. The Sword of Damocles allowed users to see a 3-D
        cube floating over the actual environment in front of them, making it a
        direct predecessor of today’s augmented reality headsets.
      </p>
      <p className="block">
        The medical, automobile design, and military training industries
        researched and implemented virtual technologies in the 1970s. Andrew
        Lippman and his team built an environmental simulation called the Aspen
        Movie Map at MIT in 1978, precursing VR’s current popularity in the
        architecture and design industries.
      </p>
      <p className="block">
        In the 1980s, the beginnings of actual VR appeared in the aerospace
        sector. Thanks to research by Sutherland, as well as NASA and the Air
        Force, pilots could put on massive helmets to simulate flight
        experiences. Jaron Lanier dubbed this sort of experience “virtual
        reality,” and released several convincing — if costly and uncomfortable
        — VR products.
      </p>
      <p className="block">
        VR captured the public imagination in the 1990s, but the technology
        wasn’t there to back up the hype. Popular books and films, like William
        Gibson’s influential Neuromancer and, of course, The Matrix, continued
        to explore the concept of computer-generated worlds. Meanwhile, the VR
        products actually available at this time still weren’t compelling to
        most consumers. Nintendo’s 1995 attempt at building a head-mounted 3-D
        display, the Virtual Boy, tanked critically and commercially. As the
        2000s came on, the mainstream obsession with VR began to decline.
      </p>
      <p className="block">
        However, virtual reality is having a renaissance in the 2010s. Since
        Palmer Luckey’s game-changing Oculus Rift headset was announced in 2012,
        companies from trendsetting startups to tech giants like Facebook and
        Google have released virtual reality platforms. Gamers, creatives,
        design professionals, and other stakeholders have driven this industry’s
        intellectual and commercial growth.
      </p>
      <p className="block">
        What was once the territory of science fiction has become a commonplace
        part of contemporary homes and workplaces. It’s undeniable: more people
        than ever are now enjoying virtual reality.
      </p>
    </div>
  </div>
)

export default History
