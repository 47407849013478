import React from 'react'
import { Layout } from '../Shared'
import helmetProps from './helmetProps'
import Hero from './sections/Hero'
import History from './sections/History'
import Hardware from './sections/Hardware'
import Computers from './sections/Computers'
import Mobile from './sections/Mobile'
import Applications from './sections/Applications'
import Future from './sections/Future'
import './WhyVR.global.scss'

const WhyVR = ({ width, images }) => {
  const widthClass = width < 850 ? 'mobile-width' : 'desktop-width'
  return (
    <div id="why-vr-page" className={`full-height ${widthClass}`}>
      <Hero splashImage={images.splash} />
      <History />
      <Hardware widthClass={widthClass} />
      <Computers images={images.computers} />
      <Mobile images={images.mobile} />
      <Applications images={images.applications} />
      <Future />
    </div>
  )
}

export default props => (
  <Layout>
    <WhyVR {...props} />
  </Layout>
)

export { helmetProps }
