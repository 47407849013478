import React from 'react'
import IrisImg from '../../../Shared/IrisImg'
import './Hero.global.scss'

const Hero = ({ splashImage }) => (
  <div className="hero-container section">
    <div className="content-container">
      <div className="accent-border" />
      <h1>What is Virtual Reality?</h1>
      <IrisImg className="splash-image" file={splashImage} />
      <div className="text-content">
        <p className="block">
          Virtual reality immerses people in simulated environments. Today, this
          means using computers, smartphones, and headsets to bring us into
          places that do not exist in our reality.
        </p>
        <p className="block">
          VR creates the illusion of <span className="bold">presence</span> in a
          different world. This is accomplished through multisensory
          technologies, like:
        </p>
        <ul className="block">
          <li>
            <p>wide-angle-video</p>
          </li>
          <li>
            <p>directional audio</p>
          </li>
          <li>
            <p>motion tracking</p>
          </li>
        </ul>
        <p className="block">
          When you are in virtual reality, you cannot perceive the outside world
          — only the digital simulation. This distinguishes VR from AR
          (augmented reality), a technology that overlays 3D models onto the
          real world.
        </p>
      </div>
    </div>
  </div>
)

export default Hero
