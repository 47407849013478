import React from 'react'
import IrisImg from '../../../Shared/IrisImg'
import Oculus from './1_Oculus'
import Vive from './2_Vive'
import WindowsMR from './3_WindowsMR'
import './Computers.global.scss'

const Computers = ({ images }) => (
  <div className="computers-container section">
    <div className="content-container">
      <h2>Computer-Powered VR Experiences</h2>
      <p className="block">
        Today, one of the most popular ways to set up a virtual reality
        experience is by connecting a headset to a computer with a robust
        graphics card. These headsets are usually paired with motion sensors and
        controllers.
      </p>
      <p className="block">
        The benefits of computer-powered VR setups include top-of-the-line
        graphics, high-quality audio, and effective motion tracking. However,
        these setups often have a higher up-front cost (as they require a
        VR-capable computer) and some movement restrictions (as the headset is
        physically wired to a computer).
      </p>
      <IrisImg className="collab" file={images.collab} />
      <p className="caption">
        Computer-powered VR is one of today’s most popular options.
      </p>
      <p className="block">The top computer-tethered VR headsets are:</p>
      <ul className="block">
        <li>
          <p>
            <span className="bold">Oculus Rift</span>, developed by the
            Facebook-owned Oculus VR.
          </p>
        </li>
        <li>
          <p>
            <span className="bold">HTC Vive</span>, developed by HTC in
            collaboration with Valve.
          </p>
        </li>
      </ul>
      <p className="block">
        Though each of these headsets have their strengths and weaknesses, both
        deliver jaw-dropping VR experiences.
      </p>
      <Oculus />
      <Vive image={images.vive} />
      <WindowsMR />
    </div>
  </div>
)

export default Computers
