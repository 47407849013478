import React from 'react'

const Gear = () => (
  <div className="gear-container">
    <h3>Samsung Gear VR</h3>
    <p className="block">
      <span className="bold">Samsung Gear VR</span>, a mobile VR headset primed
      to work with Samsung Galaxy devices, made waves when it was released in
      September 2014. Samsung worked with Oculus to develop the headset, which
      offers an easy learning curve and an accessible price point. For users
      interested in smooth and simple mobile VR, it’s an excellent option.
    </p>
  </div>
)

export default Gear
