import { getWindowLocationOrigin } from '../../utils/window'
import { generateMetaTags } from '../../utils/helmet'

const title = 'What is Virtual Reality? | IrisVR'
const description =
  'Virtual reality has transformed many industries. Learn about the history of VR, practical use cases, and VR headsets/hardware.'
const url = `${getWindowLocationOrigin()}/virtual-reality`
const image =
  'https://s3.amazonaws.com/iris-opengraph-images/ImgTag_Prospect2.jpg'
const imageWidth = 1200
const imageHeight = 630

export default generateMetaTags({
  title,
  description,
  image,
  url,
  imageWidth,
  imageHeight,
})
