import React from 'react'
import IrisImg from '../../../Shared/IrisImg'
import Google from './1_Google'
import Gear from './2_Gear'
import Go from './3_Go'
import Quest from './4_Quest'
import './Mobile.global.scss'

const Mobile = ({ images }) => (
  <div className="mobile-container section">
    <div className="content-container">
      <h2>Mobile VR Experiences</h2>
      <p className="block">
        The other main option for virtual reality today is the mobile headset.
        With the rise of the smartphone, computing power has become something
        most people hold in their pockets at all times. Thanks to this
        development, mobile VR headsets are everywhere.
      </p>
      <p className="block">
        Mobile headsets forfeit some immersive elements to lower prices and
        maximize convenience. For example, Google Daydream View and Samsung Gear
        VR have only 3 degrees of head-tracking mobility. This means users can
        look around their VR field of view by turning their head, but not move
        through it. In addition, the lack of a powerful GPU (graphics processing
        unit) in a smartphone means mobile VR systems can’t touch the visual
        detail of headsets like Rift and Vive.
      </p>
      <p className="block">
        However, mobile VR does offer more accessible pricing than any
        computer-based system. And with the recent release of the Oculus Go, the
        upcoming release of the Meta Quest 2, and other exciting developments,
        mobile VR’s quality will only continue to improve.
      </p>

      <Google image={images.cardboard} />
      <Gear />
      <Go />
      <Quest />
    </div>
  </div>
)

export default Mobile
