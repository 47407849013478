import React from 'react'

const Rift = () => (
  <div className="rift-container">
    <h3>Oculus Rift</h3>
    <p className="block">
      VR trailblazer Palmer Luckey built Oculus Rift in collaboration with John
      Carmack, a game developer best known for programming Doom and Quake. Since
      its debut at E3 2012, Oculus Rift has gone from a DIY, duct-taped
      prototype to a full-blown cultural phenomenon.
    </p>
    <p className="block">
      Oculus Rift is defined by high-resolution graphics, 6 degrees of freedom
      of movement (in other words, rotational and positional tracking), and the
      Oculus Touch controllers. The Rift can be used seated or standing. It is
      now also capable of running room-scale VR with the purchase of extra
      motion sensors.
    </p>
    <p className="block">
      In 2019, Facebook announced the Oculus Rift S - an iterative update to the
      headset that includes “inside-out tracking” capabilities (meaning that
      users won’t have to set up external sensors).
    </p>
  </div>
)

export default Rift
