import React from 'react'

const Go = () => (
  <div className="go-container">
    <h3>Oculus Go</h3>
    <p className="block">
      Oculus VR released the <span className="bold">Oculus Go</span> in May
      2018. While Oculus already had some mobile VR presence with the Gear VR,
      Go takes up a different market space. The headset requires no external
      hardware at all (not even a smartphone!), making it one of the first true
      standalone VR headsets.
    </p>
    <p className="block">
      Go provides solid VR experiences at a seriously affordable price. Its
      release contributed to a VR headset sales surge in Q2 2018 — which saw a
      40% increase in mainstream headset sales.
    </p>
    <p className="block">
      However, Go lacks significant processing power and 6 degrees of freedom.
      For users interested in otherworldly VR experiences, these bottlenecks can
      be frustrating.
    </p>
  </div>
)

export default Go
