import React from 'react'
import IrisImg from '../../../Shared/IrisImg'

const Google = ({ image }) => (
  <div className="google-container">
    <h3>Google’s Headset Options: Cardboard and Daydream View</h3>
    <p className="block">
      <span className="bold">Cardboard</span> is a low-cost, easy to use VR
      headset released by Google in 2014. Cardboard can be built by users with
      basic, readily available components, or purchased for a fraction of the
      price of more advanced headsets. Having shipped over 10 million units,
      Cardboard has reached an unparalleled level of mainstream use.
    </p>
    <IrisImg className="google image block" file={image} />
    <p className="caption">
      Thanks to low cost and easy assembly, Google Cardboard has shipped over 10
      million units since its release in 2014.
    </p>
    <p className="block">
      After the success of Cardboard, Google furthered their stake in the VR
      market with the release of Daydream in 2016. Daydream is a VR platform for
      use with compatible smartphones and{' '}
      <span className="bold">Daydream View</span>, a line of mobile VR headsets
      made of a lightweight cloth material. Consumers can also purchase
      third-party hardware for Daydream, such as the{' '}
      <span className="bold">Lenovo Mirage Solo</span>.
    </p>
  </div>
)

export default Google
